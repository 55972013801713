import { useIntl } from '@swe/shared/tools/intl';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { getSeo } from 'common/use-cases/use-seo';
import { Loyalty } from 'domains/profile/containers/loyalty';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { ShopInfo } from 'entities/shop/info';

const ProfileLoyaltyPage: ApplicationPage = () => {
  const { intl } = useIntl();

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: intl.t('pages.loyalty.title', undefined, 'Rewards'),
        defaultBackRoute: Routes.Profile,
      }}
    >
      <Loyalty />
    </Page>
  );
};

ProfileLoyaltyPage.getMeta = ({ intl, queryClient, storeConfig }) => {
  const storeInfo = queryClient.getQueryData(GetShopInfoEndpoint.key())! as ShopInfo;
  const { payload } = getSeo(storeConfig.dealerName, storeInfo);

  return {
    title: intl.t('pages.loyalty.documentTitle', payload, 'Rewards | {name} | {cityName}'),
  };
};

ProfileLoyaltyPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileLoyaltyPage;
